#browse a
{
    margin: 0 24px 0 0;
    text-transform: uppercase;
    font-family: "roc-grotesk-condensed", sans-serif;
    font-size: 28rem;
    letter-spacing: 0.05em;
}

#browse h1
{
    margin-bottom: 10rem !important;
}