.page-content-block
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    column-gap: 60px;
    row-gap: 60px;
    margin-bottom: 60px;
}

.page-content-block-photo img
{
    width: 100%;
}

.page-content-text
{
    margin-bottom: 60px;
}

@media screen and (max-width: 1555px) 
{
    .content {
        width: 90vw;
    }
}

@media screen and (max-width: 1111px) 
{
    .page-content-block
    {
        grid-template-columns: 1fr;
    }
}