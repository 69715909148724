#wishlist
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    column-gap: 60px;
    row-gap: 60px;
}

.wishlist-list-photo img
{
    width: 134px;
    height: 100px;
    display: block;
}

.wishlist-list-item
{
    background: #f9f9f9;
    display: grid;
    grid-template-columns: 134px 1fr 60px;
    margin-bottom: 8px;
}

.wishlist-list-text
{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 34px;
    height: 100%;
    font-family: "roc-grotesk-condensed", sans-serif;
    text-transform: uppercase;
}

.wishlist-list-brand
{
    font-weight: 500;
    font-size: 22rem;
}

.wishlist-list-delete 
{
    padding-top: 38px;
}

.wishlist-list-delete button
{
    font-weight: 500;
    font-size: 26rem;
    color: #7f7f7f;
}

.wishlist-list-empty
{
    background: #f9f9f9;
    height: 100px;
}

.wishlist-list-empty .wishlist-list-text
{
    padding-top: 42px;
}

#wishlist-form button
{
    background: #f9f9f9;
    padding: 12px 20px 9px 20px;
    border-radius: 12px;
    font-family: "roc-grotesk-condensed", sans-serif;
    text-transform: uppercase;
    font-size: 24rem;
    font-weight: 500;
    line-height: 1em;
}

#wishlist-form button:hover
{
    background: #f4f4f4;
}

#wishlist-form
{
    margin-top: 1.5em;
}

@media screen and (max-width: 1555px) 
{
    
}

@media screen and (max-width: 1111px) 
{
    #wishlist
    {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 777px) 
{
    
}