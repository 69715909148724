#list-filters
{
    text-transform: uppercase;
    font-family: "roc-grotesk-condensed", sans-serif;
    font-size: 20rem;
    font-weight: 500;
    margin-bottom: 30px;
}

#list-filters a:first-child
{
    margin-left: 10px;
}

#list-filters a.active
{
    color: #ffffff;
    background: #3f3f3f;
}

#list-products
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 26px;
    grid-row-gap: 30px;
    column-gap: 26px;
    row-gap: 30px;
}

.list-product.hidden
{
    display: none;
}

.list-product-photo
{
    display: block;
    position: relative;
    width: 100%;
    height: calc(100% / 4 * 3);
}

.list-product-photo img
{
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.list-product-photo-overlay
{
    background: #000000;
    opacity: 0.03;
    position: absolute;
    z-index: 100;
    inset: 0;
    pointer-events: none;
}

.list-product-brand
{
    margin-top: 20px;
    text-transform: uppercase;
    font-family: "roc-grotesk-condensed", sans-serif;
    font-size: 22rem;
    font-weight: 500;
}

.list-product-name
{
    text-transform: uppercase;
    font-family: "roc-grotesk-condensed", sans-serif;
    font-size: 18rem;
    line-height: 1.5em;
    margin-bottom: 10px;
}


@media screen and (max-width: 1555px) 
{
    
}

@media screen and (max-width: 1111px) 
{
    #list-products
    {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 777px) 
{
    #list-products
    {
        grid-template-columns: 1fr;
    }
}