/* Set padding to be subtracted from height/width instead of added to it */
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
}

a
{
    text-decoration: none;
    color: inherit;
}

html
{
    font-size: 1px;
}

body
{
    background: #ffffff;
    color: #414042;
    font-size: 18rem;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1em;
    letter-spacing: 0.05em;
}

p
{
    margin: 0;
    margin-bottom: 1.5em;
    white-space: pre-wrap;
}

#content
{
    min-height: 300px;
}

.content
{
    max-width: 1400px;
    margin: 0 auto;
}

.tag
{
    text-transform: uppercase;
    font-family: "roc-grotesk-condensed", sans-serif;
    font-size: 19rem;
    letter-spacing: 0.05em;
    font-weight: 500;
    background: #d7d6d8;
    border-radius: 6px;
    padding: 5px 10px 2px 10px;
    margin: 0;
    margin-right: 6px;
    border: 0;
    color: inherit;
    display: inline-block;
    line-height: 1em;
}

.tag.active
{
    background: #414042;
    color: #ffffff;
}

button
{
    cursor: pointer;
    margin: 0;
    border: 0;
    display: inline;
    background: none;
    color: #414042;
    font-size: 18rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1em;
    letter-spacing: 0.05em;
}

h1, h2, h3, h4, h5, h6
{
    text-transform: uppercase;
    font-family: "roc-grotesk-condensed", sans-serif;
    font-weight: 500;
    font-size: 34rem;
    line-height: 1em;
    margin: 0 !important;
    margin-bottom: 27rem !important;
}

h2, h3, h4, h5, h6
{
    font-size: 24rem;
    margin-bottom: 12rem !important;
}

h3
{
    font-size: 30rem;
    line-height: 1.5em;
    margin-bottom: 30rem !important;
    text-transform: none;
    font-weight: 400;
}

.nomargin
{
    margin: 0 !important;
}

.text
{
    line-height: 1.5em;
}

.text ul
{
    list-style-type: disc;
    margin: 0;
    padding: 0;
    margin-bottom: 1.5em;
}

.text ul li
{
    margin: 0;
    margin-bottom: 3px;
    padding: 1px 8px;
    background: #f9f9f9;
    list-style-position: inside;
}

.text a
{
    color: #888888;
}

#wishlist-button
{
    display: block;
    position: absolute;
    top: -15px;
    right: calc((100vw - 1400px) / 2);
    background: #f9f9f9;
    padding: 30px 20px 15px 20px;
    border-radius: 15px;
    font-family: "roc-grotesk-condensed", sans-serif;
    text-transform: uppercase;
}

#wishlist-button:hover
{
    background: #f4f4f4;
}

input, textarea
{
    background: #f9f9f9;
    border: 0;
    padding: 6px 12px;
    color: inherit;
    width: 100%;
}

table
{
    width: 100%;
}

iframe
{
    background: #000000;
}

iframe .player
{
    height:auto;
}

@media screen and (max-width: 1555px) 
{
    .content {
        width: 90vw;
    }

    #wishlist-button
    {
        right: 5vw;
    }
}

@media screen and (max-width: 1111px) 
{
    
}








/*
Condensed Regular
font-family: "roc-grotesk-condensed", sans-serif;
font-weight: 400;

Condensed Medium
font-family: "roc-grotesk-condensed", sans-serif;
font-weight: 500;

Extrawide Medium
font-family: "roc-grotesk-extrawide", sans-serif;
font-weight: 500;
*/