#header
{
    font-family: "roc-grotesk-extrawide", sans-serif;
    font-weight: 500;
    font-size: 125rem;
    color: #105b30;
    overflow: hidden;
    width: 100vw;
    position: absolute;
    line-height: 1em;
    height: 1em;
    top: 100px;
    letter-spacing: 0;
}

#header-scroll
{
    animation: scroll 120s linear infinite;
    position: absolute;
}

@keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }