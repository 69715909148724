#product
{
    margin-top: 30px;
}

#product-main
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    column-gap: 40px;
    row-gap: 40px;
}

#product-photos
{
    position: relative;
    width: 100%;
    overflow: hidden;
    user-select: none;
}

#product-photos-slider
{
    position: relative;
}

#product-photos-swiper
{
    width: 100%;
    height: 454px; /* default 680x454 = 3:2 ratio */
}

#product-photos img
{
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%; 
}

#product-photos-overlay
{
    background: #000000;
    opacity: 0.03;
    position: absolute;
    z-index: 100;
    inset: 0;
    pointer-events: none;
}

#product-photos-navigation
{
    position: absolute;
    z-index: 200;
    inset: 0;
    top: calc(50% - 30px);
    pointer-events: none;
}

#product-photos-button-left, #product-photos-button-right
{
    pointer-events: all;
    cursor: pointer;
    width: 60px;
    height: 60px;
    float: left;
}

#product-photos-button-right
{
    float: right;
}

#product-photos-button-left img, #product-photos-button-right img
{
    width: 100%;
    height: 100%;
}

#product-wishlist button
{
    background: #f9f9f9;
    padding: 15px 20px 12px 20px;
    border-radius: 12px;
    font-family: "roc-grotesk-condensed", sans-serif;
    text-transform: uppercase;
}

#product-wishlist button:hover
{
    background: #f4f4f4;
}

#product-wishlist
{
    margin-top: 20px;
    height: 50px;
}

#product-wishlist-add
{
    width: calc(100% - 140px);
    margin-right: 10px;
    text-align: left;
}

#product-wishlist-button
{
    width: 130px;
}

#product-shotwith
{
    margin-top: 80px;
}

#product-videos
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    column-gap: 40px;
    row-gap: 40px;
}

#product-description-container
{
    overflow-y: hidden;
    height: 524px; /* Initial height based on 3:2 photos */
    transition-property: all;
	transition-duration: 1s;
    transition-timing-function: ease-in-out;
}

#product-description-readmore
{
    position: relative;
    top: -180px;
    z-index: 1;
    width: 100%;
    height: 200px;
    margin-bottom: -200px;
    background: url('./../img/fade.svg') top center repeat-x;
    padding-top:190px;
    text-align: center;
    transition-property: all;
	transition-duration: 1s;
    transition-timing-function: ease-out;
}

#product-description-readmore button
{
    color: #888888;
    font-size: 16rem;
}

@media screen and (max-width: 1555px) 
{
    
}

@media screen and (max-width: 1111px) 
{
    #product-main, #product-videos
    {
        grid-template-columns: 1fr;
    }

    #product-description-container
    {
        overflow-y: auto;
        height: auto;
    }
}

@media screen and (max-width: 777px) 
{
    #product-wishlist button
    {
        font-size: 16rem;
    }
}