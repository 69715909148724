#menu
{
    margin-top: 250px;
    margin-bottom: 70px;
}

.menu-buttons
{
    text-align: center;
}

.menu-buttons a, #menu-hamburger
{
    margin: 0 12px;
    text-transform: uppercase;
    font-family: "roc-grotesk-condensed", sans-serif;
    font-size: 28rem;
    letter-spacing: 0.05em;
}

.menu-buttons a.active
{
    font-weight: 500;
}

.menu-submenu
{
    margin-top: 30px;
}

#menu-mobile
{
    text-align: left;
    display: none;
    height: auto;
    overflow: hidden;
}

#menu-mobile.closed
{
    max-height: 40px;
    transition: max-height 0.15s ease-out;
}

#menu-mobile.open
{
    max-height: 500px;
    transition: max-height 0.25s ease-in;
}

#menu-mobile.menu-buttons a
{
    display: block;
    margin: 12px 0;
}

.menu-button-submenu
{
    margin-left: 30px !important;
}

#menu-hamburger
{
    display: block;
    cursor: pointer;
    margin: 0;
    height: 50px;
}

#menu-hamburger img
{
    width: 28px;
    height: 28px;
    display: inline-block;
    margin-bottom: -4px;
    margin-right: 12px;
}



@media screen and (max-width: 1000px) 
{
    #menu-desktop
    {
        display: none;
    }

    #menu-mobile
    {
        display: block;
    }
}