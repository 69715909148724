#footer
{
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
}

#footer span
{
    margin:0 10px;
    letter-spacing: 0;
}

#footer-logo
{
    font-family: "roc-grotesk-extrawide", sans-serif;
    font-weight: 500;
}

.footer-socials .socials-icon
{
    margin-bottom: -8px;
}

@media screen and (max-width: 1111px) 
{
    #footer span
    {
        display: block;
        margin: 12px 0;
    }
}