#contact
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    column-gap: 40px;
    row-gap: 40px;
}

.contact-photo
{
    display: block;
    width: 100%;
    margin-bottom: 30px;
}

.contact-block-tags
{
    margin-top: 6px;
}

.contact-block-text
{
    margin-top: 30px;
}

.contact-block-social
{
    margin-top: 1.5em;
}

@media screen and (max-width: 1555px) 
{
    
}

@media screen and (max-width: 1111px) 
{
    #contact
    {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 777px) 
{
    #contact
    {
        grid-template-columns: 1fr;
    }
}